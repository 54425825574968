<template>
  <div>
    <el-scrollbar wrap-style="max-height: 60vh;" ref="configureScroll">
      <el-form label-position="right" v-loading="loading">
        <title-and-description
          :field="field"
          :hasEntityVariables="hasEntityVariables"
        />
        <el-row type="flex" :gutter="50">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="8">
            <div class="form-group">
              <el-form-item label="Entities">
                <el-select
                  v-model="field.entity_id"
                  no-data-text="No Entities available"
                  filterable
                  default-first-option
                  @change="fetchEntityDetails"
                >
                  <el-option
                    v-for="(entity, index) of entitiesData || []"
                    :value="entity._id"
                    :key="index"
                    :label="entity.name"
                  >
                    <span style="float: left">{{ entity.name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-checkbox
                :disabled="checkEntityVariable"
                v-model="field.allow_multiple"
                >Allow multiple data</el-checkbox
              >
              <el-checkbox
                v-if="field.allow_multiple"
                v-model="field.auto_complete"
                >Auto fill</el-checkbox
              >
              <el-checkbox v-model="field.detailed_view"
                >Show as detailed view link</el-checkbox
              >  -->
            </div>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Primary fields">
              <el-select
                v-model="field.primary_fields"
                no-data-text="Primary fields"
                filterable
                default-first-option
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(entity, index) of allEntityFields || []"
                  :value="entity.key"
                  :key="index + '' + entity.key"
                  :label="entity.label"
                >
                  <span style="float: left">{{ entity.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-checkbox v-model="field.allow_quick_add"
              >Allow To Add New Data</el-checkbox
            >
          </el-col>
          <el-col :span="8">
            <div class="form-group">
              <is-field-required :field="field" class="field-required" />
            </div>
          </el-col>
          <!-- <el-col :span="12"> -->
          <!-- <el-form-item label="Select type"> -->
          <!-- <el-select
              v-model="field.global_variable_entity_select_type"
              @change="setFieldType"
            >
              <el-option
                v-for="(item, index) of selectTypes"
                :key="index"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select> -->
          <!-- <el-checkbox v-model="field.allow_quick_add"
              >Allow To Add New Data</el-checkbox
            > -->
          <!-- </el-form-item> -->
          <!-- </el-col> -->

          <!-- <el-col :span="12">
          Note: Representing entities data with primary fields by default
          <div>
            <el-checkbox v-model="field.is_field_readonly"
              >is field readonly</el-checkbox
            >
          </div>
        </el-col>
        <el-col :span="12"> </el-col> -->
        </el-row>
        <el-row v-if="field.entity_id" class="mb-2">
          <el-col :span="24">
            <el-link type="primary" :underline="false" @click="openSettings"
              >View Advanced Options</el-link
            >
          </el-col>
        </el-row>
        <!-- <EntityFilters :field="field" :fieldsData="fieldsData"></EntityFilters> -->
      </el-form>
    </el-scrollbar>
    <dialog-component
      :title="'Entity advanced settings'"
      :visible="showAdvancedSettings"
      @before-close="closeSettings"
      :isShowFooter="true"
      :containerMaxWidth="'70vw'"
      :containerMaxHeight="'150vh'"
    >
      <div class="dialog-content">
        <div class="description-text">
          <EntityAdvanced
            v-if="showAdvancedSettings"
            :field="field"
            :fieldsData="fieldsData"
            :allEntityFields="allEntityFields"
          ></EntityAdvanced>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import AutomationHelper from "@/mixins/AutomationHelper";
import templateBuilderHelper from "../../../mixins/templateBuilderHelper";
import { fetchEntityById } from "@/repo/entityRepo";
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    // EntityFilters: () => import("./EntityFilters"),
    EntityAdvanced: () => import("./EntityAdvanced"),
    IsFieldRequired: () => import("./IsFieldRequired"),
  },
  mixins: [AutomationHelper, templateBuilderHelper],
  computed: {
    ...mapGetters("userTypes", ["getUserTypes"]),
    // ...mapGetters("companyEntities", [
    //   "getAllCompanyEntitiesData",
    //   "getEntityFields",
    // ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("entitiesData", ["getEntityPrimaryFieldsDataWithHash"]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataByEntityId",
      // "getEntityDataById",
    ]),

    getValidUserTypes() {
      return (this.getUserTypes.data || []).filter(
        (userType) => userType.name != "ADMIN"
      );
    },
    getValidEntities() {
      return (this.getAllEntities.data || []).filter(
        (entity) => entity.status == "ACTIVE"
      );
    },
    hasEntityVariables() {
      if (this.fieldsData && this.fieldsData.length > 0) {
        return this.fieldsData.find(
          (e) =>
            e.relationship_key == this.field.key &&
            e.input_type == "ENTITY_VARIABLE"
        )
          ? true
          : false;
      }
      return false;
    },
    checkEntityVariable() {
      if (this.fieldsData && this.fieldsData.length > 0) {
        let variables = this.fieldsData.filter(
          (e) =>
            e.relationship_key == this.field.key && e.inputType != "DATA_TABLE"
        );
        return variables.length ? true : false;
      }
      return false;
    },
  },
  props: ["field", "fieldsData", "index"],
  data() {
    return {
      validations: [],
      globalVariableTypes: ["USERS", "ENTITY"],
      selectTypes: [
        { label: "Single", value: "single" },
        { label: "Multiple", value: "multiple" },
      ],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],

      selectedEntityFields: [],
      selectedEntityFieldData: {},
      selectedEntity: {},
      entities: [],
      parent_key: "",
      actionFieldData: [],
      entitiesData: [],
      globalVariablesList: [],
      globalVariablesListForInputType: [],
      loading: false,
      filters: [],
      currentFilterIndex: -1,
      entilistAsOptions: [],
      allEntityIds: [],
      allEntitiesList: [],
      currentEntity: {},
      allEntityFields: [],
      showAdvancedSettings: false,
    };
  },
  async mounted() {
    this.loading = true;

    await this.fetchEntities();
    await this.addSelfTemplateFields();
    if (this.field.entity_id) {
      await this.fetchEntityDetails(this.field.entity_id, false);
    }

    // setTimeout(async () => {
    //   this.checkData();
    // }, 2000);
    this.loading = false;

    bus.$on("scroll-down", () => {
      if (this.$refs.configureScroll) {
        let scrollbarEl = this.$refs.configureScroll.wrap;
        scrollbarEl.scrollTop = scrollbarEl.scrollHeight;
      }
    });
  },
  methods: {
    openSettings() {
      this.showAdvancedSettings = true;
    },
    closeSettings() {
      if (
        this.field.show_count &&
        (this.field.show_count_type === "sum" ||
          this.field.show_count_type === "average") &&
        this.field.count_field.length === 0
      ) {
        this.$message.error("please select number field");
      }

      this.showAdvancedSettings = false;
    },
    getSelectedEntityCountFields(types) {
      let newFields = JSON.parse(JSON.stringify(this.allEntityFields));
      return (newFields || [])
        .filter(
          (e) =>
            types.indexOf(e.inputType) != -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
            (e.inputType == "AGGREGATE_FUNCTION" &&
              (types.indexOf("NUMBER") != -1 ||
                types.indexOf("CURRENCY") != -1))
        )
        .map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
    },
    async fetchEntityDetails(entity_id, reset = true) {
      this.loading = true;
      // await this.$store.dispatch("entities/fetchEntityById", {
      //   entity_id: entity_id,
      // })
      const getEntityDataById = await fetchEntityById(entity_id);
      if (getEntityDataById) {
        this.currentEntity = { ...getEntityDataById };
        this.allEntityFields = [];
        this.allEntityFields = this.getAllFieldsFromEntity(this.currentEntity, false, false, true);
        if (
          reset ||
          !this.field.primary_fields ||
          !this.field.primary_fields.length
        ) {
          if (this.currentEntity.primaryFields[0]) {
            this.$set(
              this.field,
              "primary_fields",
              [
                this.currentEntity.primaryFields[0].template_id +
                  "#" +
                  this.currentEntity.primaryFields[0].key,
              ] || []
            );
          }
        }
        this.checkData();
      }
      this.loading = false;
    },
    async fetchUserTypes() {
      try {
        await this.$store.dispatch("userTypes/fetchUserTypes", {
          get_all: true,
        });
      } catch (error) {
        console.log("fetchUserTypes", error);
      }
    },

    async fetchEntities() {
      try {
        // await this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        // });

        // if (this.getAllEntities) {
        //   this.entitiesData = [
        //     ...this.entitiesData,
        //     ...this.getAllEntities.data,
        //   ];
        // }
        this.entitiesData = await this.fetchAllEntities(false);
        this.entitiesData.map((entity) => {
          if (entity && entity.sections) {
            entity.sections.map((section) => {
              if (section.fields && section.fields.length) {
                section.fields.map((element) => {
                  if (element.input_type == "ACTION_BUTTON") {
                    element["template_fields"] = this.getFieldData(
                      element.action_button_repeating_template
                    );
                  }
                });
              }
            });
          }
        });
      } catch (error) {
        console.log("fetchEntities", error);
      }
    },

    async addSelfTemplateFields() {
      if (this.fieldsData && this.fieldsData.length) {
        this.fieldsData.forEach((element) => {
          if (
            element.input_type == "GLOBAL_VARIABLE" ||
            element.input_type == "ENTITY_VARIABLE"
          ) {
            element["is_template_variable"] = true;
            this.entities.push(element);
          }
        });
      }
    },

    setFieldData(fieldId) {
      let selectedEntityField = {};
      selectedEntityField = this.selectedEntityFields.find(
        (field) => field._id == fieldId
      );
      if (selectedEntityField == undefined) {
        this.actionFieldData.forEach((repeatableTemps) => {
          repeatableTemps.sections[0].fields.forEach((field) => {
            if (field._id == fieldId) {
              selectedEntityField = field;
            }
          });
        });
      }
      this.selectedEntityFieldData = selectedEntityField;
      this.field.selected_enity_field_data = this.selectedEntityFieldData;
      if (selectedEntityField && selectedEntityField.is_relational) {
        this.field.entity_id = selectedEntityField.relation_entity_id;
        this.field.relationship_entity_id = selectedEntityField.self_entity_id;
      }
      if (this.parent_key) {
        this.field.global_variable_entity_parent = this.parent_key;
      }
    },

    checkData() {
      if (this.field.global_variable_entity_type) {
        if (
          this.field.input_type == "GLOBAL_VARIABLE" &&
          this.field.global_variable_entity_field
        ) {
          this.setFieldData(this.field.global_variable_entity_field);
        }

        if (
          this.field.input_type == "ENTITY_VARIABLE" &&
          this.field.entity_id
        ) {
          this.setFieldData(this.field.global_variable_entity_field);
        }
      }
      this.entitiesData.map((entity) => {
        if (entity && entity.sections) {
          entity.sections.map((section) => {
            if (section.fields && section.fields.length) {
              section.fields.map((element) => {
                if (element.input_type == "ACTION_BUTTON") {
                  let fieldIndex = this.actionFieldData.findIndex(
                    (e) => e._id == entity.action_button_repeating_template
                  );
                  entity["template_fields"] = this.actionFieldData[fieldIndex];
                  entity["repeat_template_id"] =
                    entity.action_button_repeating_template;
                  if (
                    this.actionFieldData[fieldIndex] &&
                    this.actionFieldData[fieldIndex].primary_fields
                  ) {
                    let primaryFieldIndex = this.actionFieldData[
                      fieldIndex
                    ].primary_fields.findIndex(
                      (e) => e._id == this.data.global_variable_entity_field
                    );
                    entity["repeatable_primary_field"] =
                      primaryFieldIndex >= 0 ? true : false;
                  } else {
                    entity["repeatable_primary_field"] = false;
                  }
                }
              });
            }
          });
        }
      });
    },
    onSelectParentData(key) {
      console.log("Selected Variable Key : ", key);
      console.log(
        "Selected Variable : ",
        this.field.global_variable_entity_parent
      );
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log("getFieldData", error);
      }
    },
    setFieldType() {
      if (
        this.selectedEntityFieldData.input_type == "SELECT" ||
        this.selectedEntityFieldData.input_type == "MULTI_SELECT"
      ) {
        if (this.field.global_variable_entity_select_type == "single") {
          this.field.selected_enity_field_data.input_type = "SELECT";
          this.selectedEntityFieldData.input_type = "SELECT";
        }
        if (this.field.global_variable_entity_select_type == "multiple") {
          this.field.selected_enity_field_data.input_type = "MULTI_SELECT";
          this.selectedEntityFieldData.input_type = "MULTI_SELECT";
        }
      }
    },

    async fetchEntityPrimaryFieldsData(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
      };

      await this.$store.dispatch(
        "entitiesData/fetchEntityPrimaryFieldsData",
        params
      );

      this.loading = false;

      if (
        this.getEntityPrimaryFieldsDataWithHash &&
        this.getEntityPrimaryFieldsDataWithHash[entityId]
      ) {
        return [...this.getEntityPrimaryFieldsDataWithHash[entityId]];
      }
      return [];
    },

    async fetchEntityPrimaryFieldsDataByDataIds(entityDataIds) {
      this.loading = true;
      let params = {
        entity_data_ids: entityDataIds,
      };

      await this.$store.dispatch(
        "entitiesData/fetchEntityPrimaryFieldsData",
        params
      );

      this.loading = false;

      if (
        this.getEntityPrimaryFieldsDataWithHash &&
        this.getEntityPrimaryFieldsDataWithHash[entityDataIds.join(",")]
      ) {
        return [
          ...this.getEntityPrimaryFieldsDataWithHash[entityDataIds.join(",")],
        ];
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dialog {
  width: 100%;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #888;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
